import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDm_SYh91-AZxlzwvSVBjqXOC2EbICs36s",
    authDomain: "toolthantai.firebaseapp.com",
    databaseURL: "https://toolthantai-default-rtdb.firebaseio.com",
    projectId: "toolthantai",
    storageBucket: "toolthantai.appspot.com",
    messagingSenderId: "847618537933",
    appId: "1:847618537933:web:99c32525771ee6f8d0ec84",
    measurementId: "G-1X3GWXQHS2"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
