import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";
import Home from "./screen/Home";
import Login from "./screen/Login";
import Register from "./screen/Register";
import HomeUser from "./screen/HomeUser";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const isAuthenticated = () => {
    return isLoggedIn;
  };

  const PrivateRoute = ({ element }) => {
    return isAuthenticated() ? element : <Navigate to='/login' />;
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="/home" element={<PrivateRoute element={<Home />} />} />
          <Route
            path="/homeuser"
            element={<PrivateRoute element={<HomeUser />} />}
          />
        </Route>
        <Route path="/login" element={<Login saveLogin={handleLogin} />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </Router>
  );
};

export default App;
