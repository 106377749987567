import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from '../firebase';

const Login = (props) => {
  const { saveLogin } = props;
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const userRef = firebase.database().ref(`users/${phone}`);
      userRef.once('value', (snapshot) => {
        const userData = snapshot.val();
        if (userData && userData.password === password) {
          console.log('Đăng nhập thành công!', userData);
          setLoading(false);
          setTimeout(() => {
            if (userData.role === 'admin') {
              saveLogin();

              navigate('/home');
            } else {
              if(userData.active === false){
                  setError('Tài khoảng chưa được kích hoạt');
                  setLoading(false);
              }else{
              saveLogin();
              navigate('/homeuser');
              }
            }
          }, 2000);
        } else {
          setError('Số điện thoại hoặc mật khẩu không đúng.');
          setLoading(false);
        }
      });
    } catch (error) {
      setError('Đã xảy ra lỗi khi đăng nhập.');
      setLoading(false);
    }
  };

  return (
    <div className="login-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
      <form onSubmit={handleLogin} className="login-form" style={{ width: '300px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', padding: '20px', borderRadius: '5px', backgroundColor: 'white' }}>
        <h1 className="login-title" style={{ textAlign: 'center', marginBottom: '20px' }}>Đăng nhập</h1>
        <div className="error-message" style={{ color: 'red', textAlign: 'center', marginBottom: '10px' }}>{error && <p>{error}</p>}</div>
        <div className="input-group" style={{ marginBottom: '15px' }}>
          <label htmlFor="phone" style={{ display: 'block', marginBottom: '5px' }}>Số điện thoại</label>
          <input
            type="tel"
            id="phone"
            placeholder="0987654321"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
        </div>
        <div className="input-group" style={{ marginBottom: '15px' }}>
          <label htmlFor="password" style={{ display: 'block', marginBottom: '5px' }}>Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
        </div>
        <button type="submit" className="login-button" style={{ width: '100%', padding: '10px', borderRadius: '5px', backgroundColor: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}>
          {loading ? 'Đang đăng nhập...' : 'Đăng nhập'}
        </button>
        <div className="register-link" style={{ textAlign: 'center', marginTop: '15px' }}>
          <p>Bạn chưa có tài khoản? <a href="/register" style={{ color: '#4CAF50', textDecoration: 'none' }}>Đăng ký ngay</a></p>
        </div>
      </form>
    </div>
  );
};

export default Login;
