import React, { useState, useEffect } from 'react';
import image from '../thantai.gif';
import './HomeUser.css'; // Tạo file CSS để tùy chỉnh giao diện
import firebase from '../firebase'; // Đường dẫn tới tệp firebase.js
import loading from '../loading.gif';

const HomeUser = () => {    

  const [textData, setTextData] = useState('');

  useEffect(() => {
    // Truy vấn dữ liệu từ Firebase
    const textRef = firebase.database().ref('textData');

    textRef.on('value', (snapshot) => {
      const data = snapshot.val();
      setTextData(data || 'Default text'); // Nếu không có dữ liệu, sử dụng giá trị mặc định
    });

    // Clean up the listener when the component unmounts
    return () => textRef.off('value');
  }, []);

  return (
    <div className="home-user">
      <img src={image} alt="Your GIF" className="home-user-gif" />
      <div className="home-user-text-container">
        {textData === 'Đang phân tích' ? (
          <img src={loading} alt="Loading" className="loading-gif" />
        ) : (
          <p className="home-user-text">{textData}</p>
        )}
      </div>
    </div>
  );
};

export default HomeUser;
