// src/components/Register.js
import React, { useState } from 'react';
import firebase from '../firebase'; // Đường dẫn đến file firebase.js
import { Link } from 'react-router-dom';

const Register = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleRegister = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccessMessage(null);

    try {
      // Kiểm tra xem người dùng có tồn tại không
      const userRef = firebase.database().ref(`users/${phone}`);
      const snapshot = await userRef.once('value');

      if (snapshot.exists()) {
        setError('Số điện thoại đã được đăng ký.');
      } else {
        // Tạo một người dùng mới
        await userRef.set({
          phone : phone+"",
          password,
          role: 'user', // Gán một vai trò mặc định nếu cần thiết
          active: false,
        });

        setSuccessMessage('Đăng ký thành công!');

        // Reset trạng thái số điện thoại và mật khẩu sau khi đăng ký thành công
        setPhone('');
        setPassword('');
      }
    } catch (error) {
      setError('Đã xảy ra lỗi khi đăng ký.');
    }
  };

  return (
    <div>
      <form onSubmit={handleRegister} style={{ maxWidth: '400px', margin: 'auto' }}>
        <h1 style={{ textAlign: 'center' }}>Đăng ký</h1>
        {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
        {successMessage && <p style={{ color: 'green', textAlign: 'center' }}>{successMessage}</p>}
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="phone" style={{ display: 'block' }}>Số điện thoại</label>
          <input
            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
            type="tel"
            id="phone"
            placeholder="0987654321"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="password" style={{ display: 'block' }}>Mật khẩu</label>
          <input
            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" style={{ width: '100%', padding: '10px', borderRadius: '4px', backgroundColor: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}>Đăng ký</button>
      </form>
      <p style={{ textAlign: 'center', marginTop: '10px' }}>Đã có tài khoản? <Link to="/login">Đăng nhập</Link></p>
    </div>
  );
};

export default Register;
