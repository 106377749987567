import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import firebase from "../firebase";
import "./Home.css";

const Home = () => {
  const [activeTab, setActiveTab] = useState("analysis");
  const [text, setText] = useState("");
  const [text2, setText2] = useState("");
  const [notification, setNotification] = useState(null);
  const [userData, setUserData] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // New state variable for search term

  useEffect(() => {
    // Fetch user data when the component mounts or activeTab changes
    if (activeTab === "memberList") {
      const usersRef = firebase.database().ref("users");

      usersRef.on("value", (snapshot) => {
        if (snapshot.exists()) {
          const users = snapshot.val();
          const activeUsers = Object.entries(users)
            .filter(([key, user]) => user.role === "user")
            .map(([key, user]) => ({
              id: key,
              phone: user.phone,
              password: user.password, // Consider hashing or not storing passwords in plain text
              role: user.role,
              active: user.active,
              // Add additional user fields here
            }));
          setUserData(activeUsers);
        }
      });
    }
  }, [activeTab]);

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
    setSearchTerm(""); // Reset search term when changing tabs
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleTextChange2 = (event) => {
    setText2(event.target.value);
  };

  const handleActivateUser = (id, currentActiveStatus) => {
    const userRef = firebase.database().ref(`users/${id}`);
    userRef.update({ active: !currentActiveStatus });
  };

  const handleDeactivateUser = (id) => {
    const userRef = firebase.database().ref(`users/${id}`);
    userRef.update({ active: false });
  };

  const handleDeleteUser = (id) => {
    const userRef = firebase.database().ref(`users/${id}`);
    userRef.remove();
  };

  const handleButtonClick = () => {
    // Update the text to Firebase
    const textRef = firebase.database().ref("textData");
    textRef
      .set(text)
      .then(() => {
        setNotification("Cập nhật thành công!");
        setTimeout(() => setNotification(null), 3000); // Clear notification after 3 seconds
      })
      .catch((error) => {
        setNotification(`Failed to update text: ${error.message}`);
      });
  };

  const handleButtonClick3 = () => {
    // Update the text to Firebase
    const textRef = firebase.database().ref("textData");
    textRef
      .set(text2)
      .then(() => {
        setNotification("Cập nhật thành công!");
        setTimeout(() => setNotification(null), 3000); // Clear notification after 3 seconds
      })
      .catch((error) => {
        setNotification(`Failed to update text: ${error.message}`);
      });
  };

  const handleButtonClick2 = (text) => {
    // Update the text to Firebase
    const textRef = firebase.database().ref("textData");
    textRef
      .set(text)
      .then(() => {
        setNotification("Cập nhật thành công!");
        setTimeout(() => setNotification(null), 3000); // Clear notification after 3 seconds
      })
      .catch((error) => {
        setNotification(`Failed to update text: ${error.message}`);
      });
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "analysis":
        return (
          <div
            style={{
              maxWidth: "600px",
              margin: "20px auto",
              border: "1px solid #ccc",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              padding: "20px",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <input
                type="text"
                placeholder="Enter your text here"
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
                value={text}
                onChange={handleTextChange}
              />
              <button
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  background: "#007bff",
                  color: "white",
                  border: "none",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                  cursor: "pointer",
                }}
                onClick={handleButtonClick}
              >
                Cập nhật
              </button>
              <input
                type="text"
                placeholder="Enter your text here"
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
                value={text2}
                onChange={handleTextChange2}
              />
              <button
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  background: "#007bff",
                  color: "white",
                  border: "none",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                  cursor: "pointer",
                }}
                onClick={handleButtonClick3}
              >
                Cập nhật
              </button>
            </div>

            <div
              style={{
                maxWidth: "600px",
                margin: "20px auto",
                border: "1px solid #ccc",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                padding: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                {/* Existing content */}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  style={{
                    flex: 1,
                    padding: "10px",
                    margin: "0 5px",
                    borderRadius: "5px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handleButtonClick2("Đang phân tích")}
                >
                  Đang phân tích
                </button>
                <button
                  style={{
                    flex: 1,
                    padding: "10px",
                    margin: "0 5px",
                    borderRadius: "5px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handleButtonClick2("Chuẩn bị")}
                >
                  Chuẩn bị
                </button>
                <button
                  style={{
                    flex: 1,
                    padding: "10px",
                    margin: "0 5px",
                    borderRadius: "5px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handleButtonClick2("Sẵn sàng")}
                >
                  Sẵn sàng
                </button>
                <button
                  style={{
                    flex: 1,
                    padding: "10px",
                    margin: "0 5px",
                    borderRadius: "5px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handleButtonClick2("Đợi lệnh")}
                >
                  Đợi lệnh
                </button>
                <button
                  style={{
                    flex: 1,
                    padding: "10px",
                    margin: "0 5px",
                    borderRadius: "5px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handleButtonClick2("Bắt đầu vào lệnh")}
                >
                  Bắt đầu vào lệnh
                </button>
                <button
                  style={{
                    flex: 1,
                    padding: "10px",
                    margin: "0 5px",
                    borderRadius: "5px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handleButtonClick2("Kết thúc")}
                >
                  Kết thúc
                </button>
              </div>
            </div>
          </div>
        );
      case "memberList":
        return (
          <div>
            <h2>Danh Sách Thành Viên</h2>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <input
                type="text"
                placeholder="Search by phone number"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  padding: '10px',
                  margin: '10px 0',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                  width: '50%', // Adjust the width as necessary
                }}
              />
            </div>
            {userData ? (
              <ul>
                {userData
                  .filter((user) =>
                    user.phone.includes(searchTerm.toLowerCase())
                  )
                  .map((user) => (
                    <li
                      key={user.id}
                      style={{
                        margin: "10px 0",
                        listStyle: "none",
                        border: "1px solid #ddd",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                        padding: '10px',
                        borderRadius: '8px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                            display: 'block',
                            marginBottom: '5px',
                            padding: '10px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            borderRadius: '4px',
                          }}
                        >
                          {`STT: ${
                            userData.indexOf(user) + 1
                          } - Số điện thoại: ${user.phone}, Kích hoạt: ${
                            user.active ? 'Có' : 'Không'
                          }`}
                        </span>
                        <div>
                          <button
                            style={{
                              padding: '5px 10px',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              backgroundColor: user.active ? 'red' : 'green',
                              color: 'white',
                              marginRight: '5px',
                            }}
                            onClick={() =>
                              handleActivateUser(user.id, user.active)
                            }
                          >
                            {user.active ? 'Hủy Kích hoạt' : 'Kích hoạt'}
                          </button>
                          <button
                            style={{
                              padding: '5px 10px',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              backgroundColor: 'red',
                              color: 'white',
                            }}
                            onClick={() => handleDeleteUser(user.id)}
                          >
                            Xóa
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            ) : (
              <p>Loading user data...</p>
            )}
          </div>
        );
      case "changePassword":
        return (
          <div>
            <h2>Đổi Mật Khẩu Content</h2>
            <p>This is the content for the Đổi Mật Khẩu tab.</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <h1
        style={{
          textAlign: 'center',
          border: '1px solid #ccc',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          backgroundColor: 'blue',
          color: 'white',
        }}
      >
        Tool Thần Tài
      </h1>
      <div className="tabs-container">
        <Tabs
          activeKey={activeTab}
          onSelect={handleTabChange}
          id="uncontrolled-tab-example"
          className="mb-12"
        >
          <Tab eventKey="analysis" title="Phân Tích">
            {/* No content here, it will be dynamically rendered */}
          </Tab>
          <Tab eventKey="memberList" title="Danh Sách Thành Viên">
            {/* No content here, it will be dynamically rendered */}
          </Tab>
          <Tab eventKey="changePassword" title="Đổi Mật Khẩu">
            {/* No content here, it will be dynamically rendered */}
          </Tab>
        </Tabs>
        {renderTabContent()}

        {/* Hiển thị thông báo ở đây */}
        {notification && (
          <div className="notification">
            <p>{notification}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;

